import { useContext, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Context } from './'

export const SetImages = () => {
  const { setImages } = useContext(Context)
  const [data] = useState(useStaticQuery(graphql`
  {
    allFile(filter: {relativeDirectory: {eq: "images"}}) {
      nodes {
        publicURL
        relativePath
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`))

  useEffect(() => setImages(data.allFile.nodes), [data])

  return null
}

import { useContext, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Context } from './'

export const SetFonts = () => {
  const { setFonts } = useContext(Context)
  const [data] = useState(useStaticQuery(graphql`
  {
    allFile(filter: {childFontsJson: {title: {ne: null}}}) {
      nodes {
        name
        childFontsJson {
          title
          family
          weight
          size
        }
      }
    }
  }  
`))

console.log(data.allFile.nodes)

  useEffect(() => {
    const fonts = data.allFile.nodes.map(font => ({
      name: font.name,
      filename: font.name + '.json',
      title: font.childFontsJson.title,
      family: font.childFontsJson.family,
      weight: font.childFontsJson.weight,
      size: font.childFontsJson.size,
      variable: `var(--font-${font.name})`,
      value: `normal normal ${font.childFontsJson.weight} ${font.childFontsJson.size || '1vw'} ${font.childFontsJson.family}`
    }))

    fonts.forEach(({ name, value }) => document.documentElement.style.setProperty(`--font-${name}`, value))

    console.log('fonts', fonts)

    setFonts(fonts)
  }, [data])

  return null
}

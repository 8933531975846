import { useContext, useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Context } from './'

export const SetColors = () => {
  const { setColors } = useContext(Context)
  const [data] = useState(useStaticQuery(graphql`
  {
    allFile(filter: {childColorsJson: {title: {ne: null}}}) {
      nodes {
        name
        childColorsJson {
          color
          title
        }
      }
    }
  }
`))

console.log(data.allFile.nodes)

  useEffect(() => {
    const colors = data.allFile.nodes.map(color => ({
      name: color.name,
      filename: color.name + '.json',
      title: color.childColorsJson.title,
      color: color.childColorsJson.color,
      variable: `var(--color-${color.name})`
    }))

    colors.forEach(({ name, color }) => document.documentElement.style.setProperty(`--color-${name}`, color))

    setColors(colors)
  }, [data])

  return null
}

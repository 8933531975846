/* Packages */
import React, { createContext, useEffect, useState } from 'react'

import { SetColors } from './setColors'
import { SetFonts } from './setFonts'
import { SetImages } from './setImages'
/* Imported Components */
/* Styles */
/* Other */
const Context = createContext(undefined)
/* Component */
const ContextProvider = ({ children }) => {
  const [colors, setColors] = useState([])
  const [fonts, setFonts] = useState([])
  const [images, setImages] = useState([])

  const sizeListener = () => {
    const headerHeight = Array.from(document.getElementsByTagName('header'))[0].offsetHeight || 0
    const style = document.documentElement.style
    style.setProperty('--window-height', window.innerHeight + 'px')
    style.setProperty('--header-height', window.innerWidth * 0.07606105367 + 'px')
    style.setProperty('--main-height', window.innerHeight - (window.innerWidth * 0.07606105367) + 'px')
    style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
  }

  useEffect(() => {
    sizeListener()
    window.addEventListener('resize', sizeListener)
  }, [])

  const setVh = () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)

  useEffect(() => {
    setVh()
    window.addEventListener('resize', setVh)

    return () => window.removeEventListener('resize', setVh)
  }, [])

  const elementStyle = element => {
    // console.log('colors', colors)
    type styleObject = {
      color?: string,
      backgroundColor?: string
    }

    const object: styleObject = {}

    const textColorObject = colors.find(color => color.filename === element.settings.colors.textColor.split('/').pop())
    const backgroundColorObject = colors.find(color => color.filename === element.settings.colors.backgroundColor.split('/').pop())

    if (textColorObject) object.color = textColorObject.color
    if (backgroundColorObject) object.backgroundColor = backgroundColorObject.variable

    return object
  }

  return (
    <Context.Provider value={{ colors, elementStyle, fonts, images, setColors, setFonts, setImages }}>
      <SetColors />
      <SetFonts />
      <SetImages />
      {children}
    </Context.Provider>
  )
}
/* Exports */
export { ContextProvider, Context }
